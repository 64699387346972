import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main, Portal, GameIndex } from './components';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route exact path="/connect" element={<Portal />} />
                <Route exact path="/game" element={<GameIndex />} />
                <Route path="*" element={<Main />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
