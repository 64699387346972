import { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { LoadingView, AnimatedBird } from '../';

import './Main.scss';

export default function Main() {
    const controlsRef = useRef();

    const [targetPosition, setTargetPosition] = useState([1, -3, 0]);
    const [isEntering, setIsEntering] = useState(false);

    const handleButtonClick = () => {
        setIsEntering(true);
        // Set the target position to move the cube to
        setTargetPosition([0, -1, -10]); // Change these coordinates to where you want the cube to move
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    useEffect(() => {
        const controls = controlsRef.current;

        if (controls) {
            const originalUpdate = controls.update.bind(controls);

            controls.update = function () {
                this.target.y -= this.target.y;
                originalUpdate();
            };
        }
    }, []);

    return (
        <div
            className="main-container"
            onKeyDown={handleKeyDown}
            tabIndex={0}
            style={{ outline: 'none' }}
        >
            <div className="outline"></div>
            <button
                onClick={handleButtonClick}
                className={isEntering ? 'enter-btn disabled-btn' : 'enter-btn'}
                disabled={isEntering}
            >
                ENTER
            </button>
            <Canvas camera={{ position: [0, 0, 4], fov: 75 }}>
                <Suspense
                    fallback={
                        <Html>
                            <LoadingView />
                        </Html>
                    }
                >
                    <ambientLight intensity={4} />
                    <directionalLight position={[0, 1, 0]} intensity={2} />
                    <AnimatedBird targetPosition={targetPosition} />
                </Suspense>
            </Canvas>
        </div>
    );
}
