import { useRef, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

function Wall({ position, scale, rotation }) {
    const { camera } = useThree();
    const meshRef = useRef();
    const [opacity, setOpacity] = useState(0.2); // Initial low opacity

    useFrame(() => {
        if (meshRef.current) {
            // Compute the bounding box of the wall
            const bbox = new THREE.Box3().setFromObject(meshRef.current);

            // Get the camera's world position
            const cameraPosition = new THREE.Vector3();
            camera.getWorldPosition(cameraPosition);

            // Find the closest point on the wall's bounding box
            const closestPoint = new THREE.Vector3();
            bbox.clampPoint(cameraPosition, closestPoint);

            // Calculate the distance between the camera and the closest point
            const distance = closestPoint.distanceTo(cameraPosition);

            // Adjust the opacity based on distance
            if (distance < 20) {
                setOpacity(0.3); // Higher opacity when close
            } else {
                setOpacity(0.1); // Lower opacity when far
            }

            // Check for collision
            const cameraBox = new THREE.Box3().setFromCenterAndSize(
                cameraPosition,
                new THREE.Vector3(1, 1, 1)
            );
            if (bbox.intersectsBox(cameraBox)) {
                // Move camera back to prevent passing through
                const direction = new THREE.Vector3()
                    .subVectors(cameraPosition, closestPoint)
                    .normalize();
                camera.position
                    .copy(closestPoint)
                    .add(direction.multiplyScalar(1)); // Adjust the scalar for collision response
                camera.updateMatrixWorld();
            }
        }
    });

    return (
        <mesh ref={meshRef} position={position} rotation={rotation}>
            <boxGeometry args={scale} />
            <meshBasicMaterial
                color="#00ffcc"
                transparent={true}
                opacity={opacity}
            />
        </mesh>
    );
}

export default Wall;
