/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { useGLTF } from '@react-three/drei';

export function Ground(props) {
    const { nodes, materials } = useGLTF('/floor.glb');
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane.geometry}
                material={materials.Material}
            />
        </group>
    );
}

useGLTF.preload('/floor.glb');
