/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: ahitch3 (https://sketchfab.com/ahitch3)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/bird-animations-alex-081fa7f0cfd649b9b07babb4c619acc7
Title: Bird Animations Alex
*/

import { useRef, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

// get fbx file from mixamo with animations and model
// import into blender and export as glb (dont do anything)
// upload to https://optimizeglb.com/ to compress (60MB to 1MB!!!)
// make react component here from glb file: https://gltf.pmnd.rs/
export default function Bird(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF('/bird.glb');
    const { actions } = useAnimations(animations, group);

    useEffect(() => {
        if (props.playAnimation) {
            actions['fly1_bird'].play();
        }
    }, [props, actions]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Sketchfab_Scene">
                <primitive object={nodes._rootJoint} />
                <skinnedMesh
                    name="Object_7"
                    geometry={nodes.Object_7.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_7.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_9"
                    geometry={nodes.Object_9.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_9.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_11"
                    geometry={nodes.Object_11.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_11.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_13"
                    geometry={nodes.Object_13.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_13.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_15"
                    geometry={nodes.Object_15.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_15.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_17"
                    geometry={nodes.Object_17.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_17.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_19"
                    geometry={nodes.Object_19.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_19.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_21"
                    geometry={nodes.Object_21.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_21.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_23"
                    geometry={nodes.Object_23.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_23.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_25"
                    geometry={nodes.Object_25.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_25.skeleton}
                    scale={0.144}
                />
                <skinnedMesh
                    name="Object_27"
                    geometry={nodes.Object_27.geometry}
                    material={materials.body}
                    skeleton={nodes.Object_27.skeleton}
                    scale={0.144}
                />
            </group>
        </group>
    );
}

useGLTF.preload('/bird.glb');
