import { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Bird } from '../';
import { useNavigate } from 'react-router-dom';

export default function AnimatedBird({ targetPosition }) {
    const meshRef = useRef(null);
    const [position, setPosition] = useState([1, -3, 0]);
    const speed = 0.1;
    const navigate = useNavigate();

    useFrame(() => {
        if (meshRef.current) {
            const mesh = meshRef.current;
            let [x, y, z] = position;
            let [targetX, targetY, targetZ] = targetPosition;

            // Calculate the distance to the target position
            const dx = targetX - x;
            const dy = targetY - y;
            const dz = targetZ - z;
            const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

            // Move the cube towards the target position
            if (distance > speed) {
                setPosition([
                    x + speed * (dx / distance),
                    y + speed * (dy / distance),
                    z + speed * (dz / distance),
                ]);
            } else {
                setPosition(targetPosition); // Snap to target if close enough
            }

            if (z < -9) {
                navigate('/connect');
            }

            // Update the mesh position
            mesh.position.set(...position);
        }
    });

    return (
        <mesh ref={meshRef}>
            <Bird
                scale={0.5}
                rotation={[0, Math.PI, 0]}
                playAnimation={targetPosition[2] < 0}
            />
        </mesh>
    );
}
