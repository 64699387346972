import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';

const Background = () => {
    const texture = useLoader(THREE.TextureLoader, '/background.jpg'); // Replace with your image path

    return (
        <mesh scale={[800, 800, 800]}>
            <sphereGeometry args={[1, 32, 32]} />
            <meshBasicMaterial map={texture} side={THREE.BackSide} />
        </mesh>
    );
};

export default Background;
