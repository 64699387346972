import { useEffect, useRef } from 'react';
import Stats from 'stats.js';

const FPSDisplay = () => {
    const statsRef = useRef(null);

    useEffect(() => {
        // Create a new Stats instance
        const stats = new Stats();
        stats.showPanel(0); // Show FPS panel

        // Append the stats panel to the body
        document.body.appendChild(stats.dom);

        // Animation loop
        const animate = () => {
            stats.begin();
            // monitored code here (e.g., rendering)
            stats.end();
            requestAnimationFrame(animate);
        };

        animate();

        // Cleanup on component unmount
        return () => {
            document.body.removeChild(stats.dom);
        };
    }, []);

    return null;
};

export default FPSDisplay;
