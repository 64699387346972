import React, { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import { Vector3 } from 'three';

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Gun = (props) => {
    const { nodes, materials } = useGLTF('/space_gun/scene.gltf');
    const gunRef = useRef(null);
    const { camera, size } = useThree();
    const [holdingGun, setHoldingGun] = useState(false);
    const [gunPosition, setGunPosition] = useState([
        getRandomNumber(-115, 115),
        0.5,
        getRandomNumber(-115, 115),
    ]);
    const [gunRotation, setGunRotation] = useState(props.rotation);
    const [gunScale, setGunScale] = useState(props.scale);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                attemptToPickUpGun();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    // Calculate the position to be at the bottom of the screen
    const calculateGunPosition = () => {
        if (holdingGun && gunRef.current) {
            // Create a position vector for the gun in local space
            const gunPosition = new Vector3(0, -1, -2); // Adjust Y and Z as needed
            gunPosition.applyMatrix4(camera.matrixWorld);

            // Set the gun position to the calculated position
            gunRef.current.position.copy(gunPosition);

            // Make the gun face forward relative to the camera
            const direction = new Vector3(-100 * Math.PI, 0, 0)
                .applyMatrix4(camera.matrixWorld)
                .sub(gunRef.current.position);
            gunRef.current.lookAt(
                gunRef.current.position.clone().add(direction)
            );
        }
    };

    const attemptToPickUpGun = () => {
        const zoneThreshold = 10; // Define the pickup zone threshold distance

        // Calculate the distance between the camera and the gun
        const distance = camera.position.distanceTo(gunRef.current.position);

        // Check if the camera is within the threshold distance
        if (distance <= zoneThreshold) {
            setHoldingGun(true);
        }
    };

    useFrame(() => {
        if (holdingGun) {
            calculateGunPosition();
            setGunScale([6, 6, 6]);
            attemptToPickUpGun();
        }
    });

    return (
        <group
            {...props}
            dispose={null}
            ref={gunRef}
            position={gunPosition}
            rotation={gunRotation}
            scale={gunScale}
        >
            <group rotation={[-Math.PI / 2, 0, 0]} scale={0.002}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Sphere001_01_-_Default_0'].geometry}
                        material={materials['01_-_Default']}
                        position={[-100.928, 14.617, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Cone001_02_-_Default_0'].geometry}
                        material={materials['02_-_Default']}
                        position={[-98.997, 14.617, 0]}
                        rotation={[Math.PI, Math.PI / 2, 0]}
                        scale={[-1, 1, 1]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Tube001__0.geometry}
                        material={materials.Tube001__0}
                        position={[-91.952, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Torus001_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[-82.155, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Torus002_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[-70.96, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Torus003_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[-59.777, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Tube002_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[-28.433, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Sphere002_07_-_Default_0'].geometry}
                        material={materials['07_-_Default']}
                        position={[3.515, 14.617, 0]}
                        rotation={[0, -Math.PI / 2, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Capsule001_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[20.538, -84.529, -0.053]}
                        rotation={[-Math.PI / 2, -0.175, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={
                            nodes['ChamferCyl001_02_-_Default_0'].geometry
                        }
                        material={materials['02_-_Default']}
                        position={[7.692, -31.064, 0]}
                        rotation={[Math.PI / 2, -1.15, Math.PI / 2]}
                        scale={0.799}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={
                            nodes['ChamferBox001_08_-_Default_0'].geometry
                        }
                        material={materials['08_-_Default']}
                        position={[14.077, 14.914, 31.617]}
                        rotation={[0.019, 0.039, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Tube003_03_-_Default_0'].geometry}
                        material={materials['03_-_Default']}
                        position={[13.464, 15.447, 31.846]}
                        rotation={[0, 0.07, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Sphere003_01_-_Default_0'].geometry}
                        material={materials['01_-_Default']}
                        position={[13.49, 15.158, 33.045]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Cone002_08_-_Default_0'].geometry}
                        material={materials['08_-_Default']}
                        position={[9.697, 44.041, 0]}
                        rotation={[-Math.PI / 2, 0, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes['Sphere004_02_-_Default_0'].geometry}
                        material={materials['02_-_Default']}
                        position={[42.303, 63.149, 0]}
                    />
                </group>
            </group>
        </group>
    );
};

useGLTF.preload('/space_gun/scene.gltf');

export default Gun;
