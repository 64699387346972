import { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

// handles the first person controls
// movement, and camera following
export function FirstPersonCameraControls() {
    const { camera } = useThree();

    // State references
    const keysPressed = useRef({
        w: false,
        a: false,
        s: false,
        d: false,
        space: false,
    });
    const isJumping = useRef(false);
    const verticalVelocity = useRef(0);
    const horizontalVelocity = useRef(new THREE.Vector3());

    // Movement configuration
    const speed = 0.5; // Adjust speed as needed
    const airSpeedMultiplier = 0.5; // Reduced speed while in the air
    const groundY = 5; // Fixed y position for the plane
    const jumpHeight = 0.9; // Height to which the camera will jump
    const gravity = 0.032; // Reduced gravity effect for a floatier feel

    useEffect(() => {
        // Set initial camera position and settings
        camera.position.set(0, groundY, 5);
        camera.fov = 75; // Adjust field of view
        camera.updateProjectionMatrix();

        // Function to handle keydown events
        const handleKeyDown = (e) => {
            if (['w', 'a', 's', 'd', ' '].includes(e.key)) {
                keysPressed.current[e.key === ' ' ? 'space' : e.key] = true;
            }
        };

        // Function to handle keyup events
        const handleKeyUp = (e) => {
            if (['w', 'a', 's', 'd', ' '].includes(e.key)) {
                keysPressed.current[e.key === ' ' ? 'space' : e.key] = false;
            }
        };

        // Function to move camera based on input
        const moveCamera = () => {
            const direction = new THREE.Vector3();
            const right = new THREE.Vector3();

            // Get the camera's forward vector
            camera.getWorldDirection(direction);

            // Calculate the right vector
            right.crossVectors(camera.up, direction).normalize();

            // Reset horizontal velocity
            horizontalVelocity.current.set(0, 0, 0);

            // Determine the current speed multiplier based on jump state
            const currentSpeed = isJumping.current
                ? speed * airSpeedMultiplier
                : speed;

            // Adjust horizontal velocity based on key presses
            // forwards
            if (keysPressed.current.w)
                horizontalVelocity.current.addScaledVector(
                    direction,
                    currentSpeed
                );
            // backwards
            if (keysPressed.current.s)
                horizontalVelocity.current.addScaledVector(
                    direction,
                    -currentSpeed
                );
            // left
            if (keysPressed.current.a)
                horizontalVelocity.current.addScaledVector(right, currentSpeed);
            // right
            if (keysPressed.current.d)
                horizontalVelocity.current.addScaledVector(
                    right,
                    -currentSpeed
                );

            // Apply horizontal movement
            camera.position.add(horizontalVelocity.current);

            // Jump logic
            if (keysPressed.current.space && !isJumping.current) {
                isJumping.current = true;
                verticalVelocity.current = jumpHeight;
            }

            if (isJumping.current) {
                // Continue horizontal movement during jump
                camera.position.add(horizontalVelocity.current);

                // Apply vertical movement
                camera.position.y += verticalVelocity.current;
                verticalVelocity.current -= gravity;

                // Smooth out landing
                if (camera.position.y <= groundY) {
                    camera.position.y = groundY;
                    isJumping.current = false;
                    verticalVelocity.current = 0;
                } else if (camera.position.y - groundY < 0.1) {
                    verticalVelocity.current *= 0.9;
                }
            }

            // Constrain vertical movement if not jumping
            if (!isJumping.current) {
                camera.position.y = groundY;
            }
        };

        // Animation loop
        const handleAnimationFrame = () => {
            moveCamera();
            requestAnimationFrame(handleAnimationFrame);
        };

        // Event listeners for keyboard controls
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        // Start the animation loop
        handleAnimationFrame();

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [camera]);

    return null;
}
